<template>
  <Component :is="renderComponent" />
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRegistrationStore } from '@/stores/registration'
import { storeToRefs } from 'pinia'

import RegisterBusinessInformationOldFlow from './RegisterBusinessInformationOldFlow'
import RegisterBusinessInformationStpFlow from './RegisterBusinessInformationStpFlow'

export default {
  name: 'RegisterBusinessInformationWrapper',
  components: {
    RegisterBusinessInformationOldFlow,
    RegisterBusinessInformationStpFlow,
  },
  setup() {
    const registrationStore = useRegistrationStore()
    const { redirectToOldFlow } = storeToRefs(registrationStore)

    const renderComponent = computed(() => {
      return redirectToOldFlow.value
        ? 'RegisterBusinessInformationOldFlow'
        : 'RegisterBusinessInformationStpFlow'
    })

    return {
      renderComponent,
    }
  },
}
</script>
