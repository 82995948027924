<template>
  <AppCard>
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>{{ activeStep.pageTitle }}</h3>
      </AppCardHeader>
    </template>

    <form @submit.prevent="submit">
      <XeInputCountry
        id="companycountry"
        v-model="form.country"
        :countries="countries"
        :validation-error-message="validationErrorMessage"
        dropdown-label="Where is your business registered?"
        @input="onCountrySet($event)"
      />

      <XeInputDropdown
        v-show="isCountrySupported && !isLiteRegistration"
        id="companytype"
        v-model="form.companyType"
        option-label="name"
        :options="companyTypeList"
        option-value="id"
        dropdown-label="What type of business is it?"
        placeholder="Select a type"
        no-options-message="Please connect to the internet and try again"
        @input="onCompanyTypeSet($event)"
      />

      <AppCardFooter>
        <AppButton type="submit" :disabled="isSubmitButtonDisabled">
          {{ isCountrySupported ? 'Continue' : 'Join list' }}
        </AppButton>
      </AppCardFooter>
    </form>
  </AppCard>
</template>

<script>
import { useValidation } from 'vue-composable'
import { required } from '@vuelidate/validators'

import { reactive, ref, toRef, computed, onMounted } from '@vue/composition-api'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { useDeviceType } from '@/composables/useUserAgent'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import * as EuronetFlags from '@moneytransfer.ui/euronet-flags'
import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'
import XeInputCountry from '@/components/XeInputCountry/XeInputCountry'
import XeInputDropdown from '@/components/XeInputDropdown/XeInputDropdown'

import { useAnalyticsStore } from '@/stores/analytics'
import { useAuthStore } from '@/stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useDeviceStore } from '@/stores/device'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useConsentStore } from '@/stores/consent'
import { useRegistrationStore } from '@/stores/registration'

export default {
  name: 'RegisterBusinessInformation',
  components: {
    AppButton,
    AppCard,
    AppCardHeader,
    AppCardFooter,
    AppIcon,
    IconChevronLeft,
    XeInputCountry,
    XeInputDropdown,
  },
  setup() {
    const router = useRouter()

    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const deviceStore = useDeviceStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const consentStore = useConsentStore()
    const registrationStore = useRegistrationStore()

    corporateRegistrationStore.setActiveStep(router.currentRoute.path)
    corporateRegistrationStore.activeStepIdx = 2
    corporateRegistrationStore.setActiveStepPageTitle('Business information')

    // Send GTM variable - Registration_Email
    analyticsStore.gtmTrack({
      event: 'Registration_Email',
      variables: {
        Registration_Email: authStore.lastLogin || 'none',
      },
    })

    // Return the step data to the component
    const activeStep = corporateRegistrationStore.activeStep

    // Declare form as reactive properties
    let form = reactive({
      country: companyDetailsStore.companyCountry,
      companyType: companyDetailsStore.companyType,
    })

    // Fills country from device if blank state
    if (!form.country) {
      companyDetailsStore.setCompanyCountry(deviceStore.country)
      onCountrySet(deviceStore.country)
    }
    // Sets Initial values
    const startCountry = form.country
    const startCompanyType = form.companyType

    // Sets Local Value From Store
    const companyTypeList = computed(() => companyDetailsStore.companyTypeList)
    // Declares Values For Validation
    const validationErrorMessage = ref('')

    const countries = computed(() => {
      return corporateRegistrationStore.countryList.filter((country) => country.canRegister)
    })

    const isCountrySupported = computed(() => {
      if (!countries.value) {
        return false
      }
      const countryIndex = countries.value.findIndex((country) => country.code === form.country)
      return countryIndex >= 0
    })

    const isLiteRegistration = computed(() => authStore.isLiteRegistration)

    const currentCountrySelected = computed(() => {
      return companyDetailsStore.companyCountry
    })

    const onCountrySet = async (country) => {
      // Only triggers when updating country
      if (currentCountrySelected && currentCountrySelected !== country) {
        if (isCountrySupported) {
          companyDetailsStore.setCompanyCountry(country)
        } else {
          companyDetailsStore.setCompanyCountry('GB')
        }
      }
    }

    // Executes company types call after auth is finished
    onMounted(() => {
      // Should Check Valid Country Selected by Default
      onCountrySet(form.country)

      // Event user - acquisitionSource
      const consentState = consentStore.consentState

      if (consentState.performance) {
        consentStore.setAcquisitionSourceEvent()
      }

      // Event - profile started

      analyticsStore.identify({
        userId: authStore.profileId,
        traits: {
          email: authStore.lastLogin,
          registrationDate: new Date(Date.now()).toLocaleString().split(',')[0],
          registrationPlatform: useDeviceType(),
          product: 'XEMT',
          version: 'Corporate',
          releaseInfo: config.VUE_APP_XE_VERSION,
        },
      })

      analyticsStore.track({
        event: 'Profile Started',
        traits: {
          referenceSource: 'XEMT Business',
          email: authStore.lastLogin,
        },
      })

      //Set company type to '1' (other)
      if (isLiteRegistration.value) {
        form.companyType = '1'
      }
    })

    const onCompanyTypeSet = () => {
      companyDetailsStore.companyType = form.companyType
    }

    const validation = useValidation({
      country: {
        $value: toRef(form, 'country'),
        required,
      },
      companyType: {
        $value: toRef(form, 'companyType'),
        required,
      },
    })

    const getFlag = (flagCode) => {
      let flag = null

      if (flagCode) {
        flag = 'Flag' + flagCode.toUpperCase()
      }
      if (flag === 'FlagKV') {
        flag = 'FlagCV'
      }
      return EuronetFlags[flag] || null
    }

    const isSubmitButtonDisabled = computed(() => {
      if (!isCountrySupported.value || isLiteRegistration) {
        return validation.country.$anyInvalid
      }
      return validation.$anyInvalid
    })

    const submit = async () => {
      if (startCountry !== form.country || startCompanyType !== form.companyType) {
        corporateRegistrationStore.resetForm()
      }

      // Set company type name
      const companyTypeName = companyTypeList.value
        .filter((item) => item.id === form.companyType)
        .map(({ englishText }) => englishText)
        .toString()
      corporateRegistrationStore.companyTypeName = companyTypeName
      companyDetailsStore.setCompanyCountry(form.country)
      companyDetailsStore.companyType = form.companyType

      const companyCountryValue = companyDetailsStore.companyCountry || form.country || 'none'

      analyticsStore.identify({
        userId: authStore.profileId,
        traits: {
          companyCountry: companyCountryValue,
          companyTypeId: form.companyType,
          companyType: companyTypeList.value.find((item) => item.id === form.companyType).name,
        },
      })

      analyticsStore.track({
        event: 'Business Type Selected',
        traits: {
          companyCountry: companyCountryValue,
          companyType: companyTypeList.value.find((item) => item.id === form.companyType).name,
          referenceSource: 'XEMT Business',
          email: authStore.lastLogin,
        },
      })

      // Send GTM variable - registration_country
      analyticsStore.gtmTrack({
        event: 'Registration_Country',
        variables: {
          Registration_Country: companyCountryValue,
        },
      })

      if (isLiteRegistration.value) {
        router.push({ name: 'RegisterBusinessDetails' })
      } else if (registrationStore.formFilledInformationAllowsStpFlow) {
        // Handles STP Allowed user redirection to STP Flow
        registrationStore.redirectToOldFlow = false
        router.push({ name: 'RegisterPersonalDetails' })
      } else {
        // Routing user to business search page for non-stp users
        if (isCountrySupported.value) {
          corporateRegistrationStore.fetchStateList(form.country)
          corporateRegistrationStore.fetchStreetTypeList(form.country)
          corporateRegistrationStore.fetchNatureOfBusinessList()
        }
        registrationStore.redirectToOldFlow = true
        router.push({ name: 'RegisterBusinessSearch' })
      }
    }

    const goBack = () => authStore.sendToContinueAs()

    return {
      activeStep,
      countries,
      companyTypeList,
      form,
      getFlag,
      isCountrySupported,
      isLiteRegistration,
      isSubmitButtonDisabled,
      onCompanyTypeSet,
      onCountrySet,
      submit,
      validation,
      validationErrorMessage,
      goBack,
      mq: reactive(useMediaQuery()),
      currentCountrySelected,
    }
  },
}
</script>
