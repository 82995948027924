<template>
  <AppCard class="card-stp">
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>{{ stepConfig.mainTitle }}</h3>
      </AppCardHeader>
    </template>

    <!-- mobile only -->
    <h3 class="type-h2 pb-4 sm:hidden">{{ stepConfig.mainTitle }}</h3>

    <form @submit.prevent="submit">
      <div v-if="isLoadingOptions" class="my-10 text-center text-orange">
        <AppSpinner :size="32" :loading="isLoadingOptions"></AppSpinner>
      </div>
      <div v-else>
        <p class="type-caption-bold pb-8">
          Tell us about a few of your preferences and we can tailor you new account to your exact
          needs.
        </p>
        <AppDynamicForm :fields="companyInformation" :disabled="isLoading" />
        <!-- Custom currency amount fields for STP -->
        <div class="currency-amount">
          <div class="input-label">{{ currencyAmountLabel }}</div>
          <AppDynamicForm
            class="currency-amount-items"
            :fields="currencyAmount"
            :disabled="isLoading"
          />
        </div>
        <AppDynamicForm :fields="firstTrade" :disabled="isLoading" />
      </div>

      <AppCardFooter>
        <AppButton type="submit" :disabled="isSubmitButtonDisabled" :loading="isLoading">
          Continue
        </AppButton>
      </AppCardFooter>
    </form>
  </AppCard>
</template>

<script>
import { computed, reactive, onBeforeMount, ref, watch } from '@vue/composition-api'

import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { getFieldValue } from '@/utils/form'

import { useRegistrationForm } from '@/forms/CorporateRegistrationForm'

import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppButton from '@/components/AppButton/AppButton'
import AppSpinner from '@/components/AppSpinner/AppSpinner'

import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'

import { useAnalyticsStore } from '@/stores/analytics'
import { useAuthStore } from '@/stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useRegistrationStore } from '@/stores/registration'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/stores/app'
import { setVolumeLabel } from '@/helpers/businessInformation'

export default {
  name: 'RegisterBusinessInformationSTP',
  components: {
    AppCard,
    AppCardHeader,
    AppCardFooter,
    AppDynamicForm,
    AppIcon,
    AppButton,
    IconChevronLeft,
    AppSpinner,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const registrationStore = useRegistrationStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const appStore = useAppStore()

    // TO CHECK: this might go horribly wrong :(
    const { currencyTypeList, activeStep } = storeToRefs(corporateRegistrationStore)

    const {
      setup: setupFields,
      isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useRegistrationForm(store)

    // Default data
    const currencyAmountLabel = ref('')
    const isLoadingOptions = ref(true)
    const companyType = companyDetailsStore.companyType

    const currentCompanyType = computed(() => companyDetailsStore.companyType)
    const currentCountry = computed(() => companyDetailsStore.companyCountry)
    const companyTypeList = computed(() => companyDetailsStore.companyTypeList)
    // Get STP config
    const stepConfig = registrationStore.getRegistrationStepConfigById('companyInformation')

    // Setup everything to render properly
    onBeforeMount(async () => {
      // Get mocked fields for this step
      corporateRegistrationStore.getMockedCustomerRequiredFields()
      // Tell the store which step we are on
      corporateRegistrationStore.setActiveStep(router.currentRoute.path)
      corporateRegistrationStore.activeStepIdx = stepConfig.stepId
      corporateRegistrationStore.setActiveStepPageTitle(stepConfig.stepTitle)

      // Set currency list
      corporateRegistrationStore.fetchCurrencyTypeList({ country: currentCountry.value })

      // Setup fields
      let { fields } = corporateRegistrationStore.getMockedCustomerFields('company')
      // Filtering by fields
      const fieldsToInclude = stepConfig?.fieldsToInclude
      fields = fields.filter(({ id }) => fieldsToInclude.includes(id))
      // Setup currency amount field
      fields = fields.map((field) => {
        if (field.id === 'expectedannualtradingvolume') {
          // set currency amount label
          currencyAmountLabel.value = setVolumeLabel(currentCountry.value, field.label)
        }
        if (field.id === 'currencytype' || field.id === 'expectedannualtradingvolume') {
          // Remove label to fit with Figma design
          field.label = ''
        }
        return field
      })
      // Render fields
      watch(currencyTypeList, () => {
        if (fields) {
          // Create fields instance
          setupFields(fields, 'company')
          // Add custom props values
          formFields.value.forEach((field, i) => {
            // Set country values
            if (field.id === 'country') {
              // Set company country
              companyDetailsStore.setCompanyCountry(currentCountry.value)
              // Set props values
              field.value = currentCountry.value
              field.disabled = true
              // Listening events
              formFields.value[i]['listeners'] = {
                input: (country) => {
                  companyDetailsStore.setCompanyCountry(country)
                },
              }
            }
            // Set amount dropdown
            if (field.id === 'companytype') {
              field.props.optionLabel = 'name'
              field.props.optionValue = 'id'
              field.props.options = companyTypeList
              field.value = currentCompanyType.value
              // Set company type
              formFields.value[i]['listeners'] = {
                input: (country) => (companyDetailsStore.companyType = country),
              }
            }
            // Set new look and feel for currency field
            if (field.id === 'currencytype') {
              field.props.isOnlyCurrencyNameOption = true
            }
          })
          isLoadingOptions.value = false
        }
      })
    })
    const getFieldOptionText = (id, toFind, toReturn) => {
      const field = formFields.value.find((field) => field.id === id)
      const selectedOptionText = Object.values(field.props.options).map((option) => {
        if (option[toFind] === getFieldValue(id, formFields)) {
          return option[toReturn]
        }
      })
      return selectedOptionText.filter(Boolean).toString()
    }
    // Section 1 - Company country and type
    const companyInformationFields = ['country', 'companytype']
    const companyInformation = computed(() =>
      formFields.value.filter((field) => companyInformationFields.includes(field.id))
    )
    // Section 2 - Currency and amount expected
    const currencyFields = ['currencytype', 'expectedannualtradingvolume']
    const currencyAmount = computed(() =>
      formFields.value.filter((field) => currencyFields.includes(field.id))
    )
    // Section 3 - First trade estimate
    const firstTradeFields = ['expectedtotradein']
    const firstTrade = computed(() =>
      formFields.value.filter((field) => firstTradeFields.includes(field.id))
    )
    const isSubmitButtonDisabled = computed(() => !hasFormFields.value || isFormInvalid.value)

    const goBack = () => router.push({ name: 'RegisterPersonalDetails' })
    const submit = async () => {
      try {
        isLoadingOptions.value = true
        // Check if company type change to clean up store on business details page
        companyDetailsStore.hasCompanyTypeChanged =
          Number(companyType) !== Number(currentCompanyType.value)
        // Store currency and amount fields
        await corporateRegistrationStore.submitFieldData({
          group: 'company',
          formFields: formFields.value.slice(-2),
          send: false,
        })
        // Save fields for later
        await corporateRegistrationStore.saveFormFields({
          group: 'company',
          formFields,
        })
        isLoadingOptions.value = false
        // Sets the redirect to stp flow value
        if (registrationStore.formFilledInformationAllowsStpFlow) {
          registrationStore.redirectToOldFlow = false
        } else {
          const country = formFields.value.find((field) => field.id === 'country')
          if (country.value) {
            corporateRegistrationStore.fetchStateList(country.value)
            corporateRegistrationStore.fetchStreetTypeList(country.value)
            corporateRegistrationStore.fetchNatureOfBusinessList()
          }
          registrationStore.redirectToOldFlow = true
        }
        // Event data parsing
        const companyCountry = formFields.value.find((field) => field.id === 'country').value
        const selectedCompany = formFields.value.find((field) => field.id === 'companytype')
        const companyTypeName = selectedCompany.props.options.find(
          (option) => option.id === selectedCompany.value
        ).name
        const email = authStore.lastLogin

        // Event Handling
        analyticsStore.identify({
          userId: authStore.profileId,
          traits: {
            companyCountry,
            companyTypeId: getFieldValue('companytype', formFields),
            companyType: companyTypeName,
            currency: getFieldValue('currencytype', formFields),
            amount: getFieldOptionText('expectedannualtradingvolume', 'volume', 'text'),
            firstTradeEstimate: getFieldValue('expectedtotradein', formFields),
          },
        })

        analyticsStore.track({
          event: 'Business Type Selected',
          traits: {
            companyCountry,
            companyType: companyTypeName,
            referenceSource: 'XEMT Business',
            email,
            currency: getFieldValue('currencytype', formFields),
            amount: getFieldOptionText('expectedannualtradingvolume', 'volume', 'text'),
            firstTradeEstimate: getFieldValue('expectedtotradein', formFields),
          },
        })
        // Send GTM variable - registration_country
        analyticsStore.gtmTrack({
          event: 'Registration_Country',
          variables: {
            Registration_Country:
              currentCountry.value || getFieldValue('registrationnumber', formFields),
          },
        })
        // Send GTM variable - Registration_Email
        analyticsStore.gtmTrack({
          event: 'Registration_Email',
          variables: {
            Registration_Email: authStore.lastLogin || 'none',
          },
        })
        // Push to next step, both cases Business Search
        router.push({ name: 'RegisterBusinessSearch' })
      } catch (ex) {
        appStore.logException({
          text: 'Exception during submitting business information',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
    }

    return {
      mq: reactive(useMediaQuery()),
      activeStep,
      formFields,
      isLoading,
      isSubmitButtonDisabled,
      currencyAmount,
      currencyAmountLabel,
      companyInformation,
      firstTrade,
      isLoadingOptions,
      stepConfig,
      goBack,
      submit,
    }
  },
}
</script>

<style lang="postcss">
.currency-amount {
  &-items {
    @apply flex justify-between;

    > div {
      &:nth-child(1) {
        @media (max-width: 350px) {
          width: 41%;
        }
        flex-basis: 60%;
        @apply mr-3;
      }

      &:nth-child(2) {
        @media (max-width: 350px) {
          width: 53%;
        }

        flex-basis: 100%;
      }
    }
  }
}
</style>
